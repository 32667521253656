import {
  HomeTemplate,
  HomeTemplateProps,
} from '@/components/template/HomeTemplate/HomeTemplate';
import { GetStaticProps } from 'next';

import { SocialSharingTags } from '@/components/molecules/SocialSharingTags';
import { parseBuildTrim } from '@/components/organisms/ChooseTrimForm';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import { RawTrimDetails } from '@/types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getSearchMakesAndModels } from '@/lib/getSearchMakesAndModels';

export default function Home(props: HomeTemplateProps) {
  const {
    bestForFamily = [],
    mostAffordable = [],
    longestRange = [],
    showNewHero,
    makes,
    models,
  } = props;
  return (
    <main>
      <SocialSharingTags
        title={SEOMetaTags.homePage.title}
        description={SEOMetaTags.homePage.description}
      />
      <HomeTemplate
        bestForFamily={bestForFamily}
        longestRange={longestRange}
        mostAffordable={mostAffordable}
        showNewHero={showNewHero}
        makes={makes}
        models={models}
      />
    </main>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  if (process.env.NEXT_PUBLIC_NEW_LANDING_PAGE_ENABLED === 'true') {
    const promises = ['range', 'family', 'price'].map((sortBy) =>
      fetch(`${process.env.API_ENDPOINT}/api/build/selector?sortBy=${sortBy}`)
    );

    const responses = await Promise.all(promises);
    const jsons = await Promise.all(
      responses.map((response) => response.json() as Promise<RawTrimDetails[]>)
    );

    const autoCompleteResults = await getSearchMakesAndModels();

    return {
      props: {
        ...(await serverSideTranslations(locale ?? 'en', ['common'])),
        longestRange: jsons[0].map(parseBuildTrim),
        bestForFamily: jsons[1].map(parseBuildTrim),
        mostAffordable: jsons[2].map(parseBuildTrim),
        showNewHero: process.env.NEXT_PUBLIC_ENABLE_VIDEO_HOME_PAGE === 'true',
        makes: autoCompleteResults.searchMakes,
        models: autoCompleteResults.searchModels,
      },
      revalidate: 60,
    };
  }
  return {
    props: {},
  };
};
