import { ScComponent } from '@/types/interfaces';
import React from 'react';

export interface RotatorDotProps
  extends ScComponent,
    Pick<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'disabled'
    > {
  label: string;
  active?: boolean;
  edge?: boolean;
}

const RotatorDot: React.FC<RotatorDotProps> = ({
  className = '',
  label = 'button',
  active = false,
  edge = false,
  ...props
}) => (
  <button
    className={`h-[10px] w-[10px] rounded-full bg-neutral-300 bg-cover bg-center bg-no-repeat ${
      active ? 'rounded-full bg-neutral-800' : ''
    } ${edge ? 'h-s w-s' : ''} ${className}`}
    type="button"
    aria-label={label}
    {...props}
  ></button>
);

export default RotatorDot;
