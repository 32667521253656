import React, { PropsWithChildren } from 'react';
import NextLink, { LinkProps as NextLinkProp } from 'next/link';
import { BaseButtonProps } from '@/types';
import TextButton, { TextButtonVariants } from '../TextButton/TextButton';

export interface ButtonLinkProps
  extends Omit<BaseButtonProps, 'onClick' | 'onMouseEnter'>,
    NextLinkProp {
  variant?: TextButtonVariants;
  href: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

const ButtonLink: React.FC<PropsWithChildren<ButtonLinkProps>> = ({
  href,
  variant = TextButtonVariants.Default,
  children,
  ...props
}) => {
  return (
    <NextLink aria-label={`link to ${href}`} href={href}>
      <TextButton {...props} variant={variant}>
        {children}
      </TextButton>
    </NextLink>
  );
};

export default ButtonLink;
