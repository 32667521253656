import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { MakeIcon } from '@/components/atoms/MakeIcons/MakeIcon';
import { POPULAR_MAKES_EXTENDED } from '@/lib/constants';
import clsx from 'clsx';
import React, { FC, useCallback } from 'react';

export const ShopByPopularMakes: FC<{
  onClick: (make: string) => void;
  allMakes: string[];
}> = ({ onClick, allMakes }) => {
  const [viewAll, setViewAll] = React.useState(false);
  const handleViewAll = useCallback(() => {
    if (viewAll) {
      setViewAll(false);
      const element = document.getElementById('popular-makes-header');
      if (element) {
        setTimeout(() => {
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
          const offset = 100;

          window.scrollTo({
            top: elementPosition - offset,
            behavior: 'smooth',
          });
        }, 50);
      }
    } else {
      setViewAll(true);
    }
  }, [viewAll]);

  const sortedAllMakes = POPULAR_MAKES_EXTENDED.concat(
    allMakes.filter((make: string) => !POPULAR_MAKES_EXTENDED.includes(make))
  );

  const makesList = viewAll ? sortedAllMakes : POPULAR_MAKES_EXTENDED;

  return (
    <div className="flex flex-col gap-xl pb-3xl m:pb-[64px]">
      <div
        id={'popular-makes-header'}
        className="flex w-full justify-center text-h2Regular text-neutral-900"
      >
        Popular makes
      </div>
      <div className="flex w-full justify-center">
        <div className="grid w-full grid-cols-2 justify-center gap-3xl m:max-w-[1038px] m:grid-cols-6">
          {makesList.map((make, i) => {
            return (
              <div
                key={`popular-makes-${make}-${i}`}
                onClick={() => onClick(make)}
                className="group flex cursor-pointer flex-col justify-center gap-s"
              >
                <div className="relative flex justify-center">
                  <div className="relative flex h-full w-full items-center justify-center rounded-full">
                    <MakeIcon
                      className="relative z-10 flex aspect-square items-center justify-center rounded-full bg-neutral-100 p-l shadow-s group-hover:shadow-l"
                      width={48}
                      height={48}
                      make={make}
                    />
                    <div
                      className={clsx(
                        'gradient absolute z-0 m-m h-[112px] w-[112px] rounded-full bg-neutral-300 bg-gradient-to-b from-[rgba(255,255,255,.7)] to-[rgba(255,255,255,.1)] opacity-0 transition-opacity duration-250 group-hover:opacity-100',
                        {
                          '!bg-[#DF3339]': make === 'Tesla',
                          '!bg-[#102B72]':
                            make === 'Ford' || make === 'Hyundai',
                          '!bg-[#E5B60F]':
                            make === 'Chevrolet' || make === 'Rivian',
                          '!bg-[#080812]': make === 'Kia',
                        }
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-center pt-[10px] text-body1Medium text-neutral-800">
                  {make}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div className="flex w-fit-content">
          <Button
            aria-label={'View all makes'}
            variant={ButtonVariants.Secondary}
            onClick={handleViewAll}
          >
            {viewAll ? 'View less' : 'View all makes'}
          </Button>
        </div>
      </div>
    </div>
  );
};
