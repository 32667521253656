import { ResponsiveMargin } from '@/components/atoms/legacy/ResponsiveMargin/ResponsiveMargin';
import { CalculatorCarousel } from '@/components/molecules/CalculatorCarousel/CalculatorCarousel';
import useWindowSize from '@/hooks/useWindowSize';
import { useState, FC } from 'react';

const PAYMENT_CALCULATOR_COPY = {
  header: 'Calculate your monthly vehicle payment',
  subHeader: 'Discover hundreds of EVs for sale within your budget.',
};
const GVE_CALCULATOR_COPY = {
  header: 'EV vs Gas comparison',
  subHeader:
    'Find out why EV ownership has cheaper running costs than a gasoline-powered vehicle.',
};

export const CalculatorSection: FC = () => {
  const { isMobile } = useWindowSize();
  const [mode, setMode] = useState('payment');

  return (
    <section className="bg-gradient-to-r from-lightTheme-start to-lightTheme-end py-3xl m:pb-xl m:pt-[80px]">
      <ResponsiveMargin variant="template" right={isMobile}>
        <div className="flex w-full flex-col gap-xl m:flex-row">
          <div className="flex w-full m:w-4/12">
            <div className="flex items-center">
              <div className="flex h-full flex-col justify-center gap-s">
                <p className="max-w-[400px] text-h2SemiBold text-brandMidnight m:max-w-full">
                  {mode === 'payment'
                    ? PAYMENT_CALCULATOR_COPY.header
                    : GVE_CALCULATOR_COPY.header}
                </p>
                <p className="text-body1Regular text-brandMidnight">
                  {mode === 'payment'
                    ? PAYMENT_CALCULATOR_COPY.subHeader
                    : GVE_CALCULATOR_COPY.subHeader}
                </p>
              </div>
            </div>
          </div>
          <div className="h-content hidden w-full items-center m:flex m:w-8/12">
            <CalculatorCarousel setMode={setMode} />
          </div>
        </div>
      </ResponsiveMargin>

      <div className="h-content flex w-full items-center m:hidden">
        <CalculatorCarousel setMode={setMode} />
      </div>

      <ResponsiveMargin variant="template">
        <span className="flex text-microLight text-neutral-600">
          The finance calculator estimates are for convenience only and are not
          a finance offer or credit guarantee. The estimate excludes taxes,
          title, registration, vehicle sale, and financing fees. Costs may vary
          within states. Comparison vehicles are based on average EV and
          gasoline consumption rates.
        </span>
      </ResponsiveMargin>
    </section>
  );
};
