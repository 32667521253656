import React, { useEffect, useRef, useState } from 'react';
import CarouselDirectionButton from '@/components/atoms/legacy/CarouselButton';
import { Direction } from '@/types/enums';
import useWindowSize from '@/hooks/useWindowSize';
import RotatorDot from '@/components/atoms/legacy/RotatorDot';
import { throttle } from 'lodash';
import { ResponsiveMargin } from '@/components/atoms/legacy/ResponsiveMargin/ResponsiveMargin';
import { PaymentCalculator } from '../PaymentCalculator/PaymentCalculator';
import { GVECalculator } from '../GVECalculator/GVECalculator';
import Carousel from '../Carousel';

export interface CarouselProps {
  setMode: (mode: 'payment' | 'gve') => void;
}

export const CalculatorCarousel: React.FC<CarouselProps> = ({ setMode }) => {
  const { isMobile, isDesktop } = useWindowSize();
  const [page, setPage] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(isDesktop);

  const clickNext = throttle(() => {
    if (sliderRef.current && carouselRef.current) {
      sliderRef.current.style.transform = 'translate(-25%)';
      setPage(page === 0 ? 1 : 0);
      setMode(page === 0 ? 'gve' : 'payment');
    }
  }, 500);

  useEffect(() => {
    const slider = sliderRef.current;
    const handleTransition = (event: TransitionEvent) => {
      if (
        slider &&
        event.propertyName === 'transform' &&
        event.target === slider
      ) {
        slider.appendChild(slider.children[0]);

        slider.style.transition = 'none';
        slider.style.transform = 'translate(0)';
        setTimeout(() => {
          if (slider) {
            slider.style.transition = 'transform .5s';
          }
        });
      }
    };
    slider?.addEventListener('transitionend', handleTransition);
    return () => {
      slider?.removeEventListener('transitionend', handleTransition);
    };
  }, []);

  const slides = [
    <div
      key={'payment-calc'}
      className={`flex w-full pr-xl m:w-full ${page === 0 ? 'opacity-100' : 'opacity-60'} transition-opacity duration-500 ease-in-out`}
    >
      <PaymentCalculator
        productPrice={40000}
        showSearchBudgetButton={true}
        fixedPrice={false}
        isHomePage={true}
        totalIncentiveAmount={0}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    </div>,
    <div
      key={'gve-calc'}
      className={`flex w-full pr-xl m:w-full ${page === 1 ? 'opacity-100' : 'opacity-60'} transition-opacity duration-500 ease-in-out`}
    >
      <GVECalculator
        expanded={expanded}
        setExpanded={setExpanded}
        isHomePage={true}
      />
    </div>,
  ];

  const Nav = (
    <ResponsiveMargin left={false}>
      <div className="relative flex pb-xl pt-l m:pb-l">
        <div className="flex flex-grow items-center justify-center space-x-[3px]">
          <RotatorDot
            key={0}
            edge={true}
            active={page === 0}
            label={'0'}
            onClick={() => {
              if (page !== 0) {
                clickNext();
              }
            }}
          />
          <RotatorDot
            key={1}
            edge={true}
            active={page === 1}
            label={'1'}
            onClick={() => {
              if (page !== 1) {
                clickNext();
              }
            }}
          />
        </div>
        {!isMobile && (
          <CarouselDirectionButton
            direction={Direction.Right}
            onClick={clickNext}
          />
        )}
      </div>
    </ResponsiveMargin>
  );

  const infiniteCarousel = (
    <div className="relative flex h-full w-full flex-col">
      <div className="h-full w-full overflow-hidden py-xl pl-l">
        <div ref={carouselRef} className="flex h-full w-full justify-start">
          <div
            ref={sliderRef}
            id="slider"
            className="flex h-full w-[375%] shrink-0 transition-all duration-500 ease-in-out m:w-[320%]"
          >
            {slides.map((slide, index) => {
              return (
                <section
                  key={index}
                  className="relative flex w-[80%] flex-1 flex-shrink-0"
                  onClick={() => {
                    if (page !== index) {
                      clickNext();
                    }
                  }}
                >
                  {slide}
                </section>
              );
            })}
            {slides.map((slide, index) => (
              <section
                key={`overflow-${index}`}
                className="relative flex w-[80%] flex-1 flex-shrink-0"
                onClick={() => {
                  if (page !== index) {
                    clickNext();
                  }
                }}
              >
                {slide}
              </section>
            ))}
          </div>
        </div>
      </div>
      {Nav}
    </div>
  );

  return (
    <>
      <div className="hidden w-full m:flex">{infiniteCarousel}</div>
      <div className="relative flex h-full w-full flex-col m:hidden">
        <Carousel
          imagesLength={2}
          snap={true}
          showDirectionButtons={false}
          navigationPosition="bottom"
          currentIndex={page}
          setCurrentIndex={(val: number) => {
            setPage(val);
            setMode(val === 0 ? 'payment' : 'gve');
          }}
          slideWrapperClassName="p-xl"
        >
          {slides}
        </Carousel>
        {Nav}
      </div>
    </>
  );
};
