import React, { FC } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { useZipContext } from '@/context/ZipContext';
import { handleFilteredSearch } from '@/lib/search';
import Badge, { BadgeVariants } from '../Badge';

export const IncentivesCardHomepage: FC = () => {
  const { userZip, customZip } = useZipContext();

  return (
    <div
      role="button"
      aria-label="Search EVs with incentives"
      onClick={() =>
        handleFilteredSearch({
          input: '',
          zip: customZip || userZip?.postal,
          country: userZip?.country,
          incentives: true,
          showRebates: true,
        })
      }
      className="group flex w-full flex-col gap-s overflow-hidden rounded-small bg-blue-medium p-2xl"
    >
      <div className="flex">
        <Badge variant={BadgeVariants.Gray} label="Incentives" />
      </div>
      <div className="flex text-h2SemiBold text-neutral-0">
        Save up to $7,500 or more with qualifying incentives
      </div>
      <div className="text-body1Regular text-neutral-0">
        We&apos;ll help you navigate federal incentives to maximize your
        savings.
      </div>
      <div className="flex items-center gap-s py-l text-body1Medium text-neutral-0">
        Search EVs <ChevronRightIcon className="h-l w-l" />
      </div>
      <div className="pointer-events-none relative flex h-[175px] justify-end m:max-h-[200px]">
        <div className="absolute right-[-125px] top-[-90px] group-hover:animate-spinYAxis ml:right-[-50px] ml:top-[-60px]">
          <Icon iconId={IconIds.IncentiveMoneySignFull} />
        </div>
      </div>
    </div>
  );
};
