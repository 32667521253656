import Image from '@/components/atoms/Image';
import Link from '@/components/atoms/legacy/Link';
import { COMPARE_EVS, GOOGLE_TRACK_INFO } from '@/lib/constants';
import { FC, useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import useWindowSize from '@/hooks/useWindowSize';
import Badge, { BadgeVariants } from '../Badge';

const VehicleFinder: FC = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % 3);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Link
      href="/compare"
      analyticsEvent={GOOGLE_TRACK_INFO.compareEVHomePageButton}
      aria-label={'go to vehicle compare'}
      className="flex w-full"
    >
      <div
        className={`flex h-full w-full flex-col justify-between gap-s overflow-hidden rounded-small bg-gradient-to-br from-neutral-0 to-[#0edab533] py-2xl`}
      >
        <div className="flex flex-col gap-s px-2xl">
          <div className="flex">
            <Badge variant={BadgeVariants.Gray} label="Compare EVS" />
          </div>
          <div className="text-h2SemiBold text-neutral-900">
            Compare the latest makes and models quickly and easy
          </div>
          <div className="text-body1Regular text-neutral-800">
            We&apos;ll help you make the best decision.
          </div>
          <div className="flex items-center gap-s py-l text-body1Medium text-blue-medium">
            Get started <ChevronRightIcon className="h-l w-l" />
          </div>
        </div>

        <div className="relative flex h-[200px] w-full translate-x-[-10%] items-center pt-l m:h-[150px] m:translate-x-[-7%]">
          <div
            className="absolute flex h-[200px] w-full transition-opacity duration-500 ease-in-out m:h-[150px] m:w-3/4"
            style={{
              opacity: currentImage === 0 ? 1 : 0,
            }}
          >
            <Image
              src={`/images/compare/compare1${isMobile ? '_mobile' : ''}.png`}
              fill
              alt="compare"
              className={'h-[200px] w-auto object-contain m:h-[150px]'}
            />
          </div>
          <div
            className="absolute flex h-[200px] w-full transition-opacity duration-500 ease-in-out m:h-[150px] m:w-3/4"
            style={{
              opacity: currentImage === 1 ? 1 : 0,
            }}
          >
            <Image
              src={`/images/compare/compare2${isMobile ? '_mobile' : ''}.png`}
              fill
              alt="compare"
              className={'h-[200px] w-auto object-contain m:h-[150px]'}
            />
          </div>
          <div
            className="absolute flex h-[200px] w-full transition-opacity duration-500 ease-in-out m:h-[150px] m:w-3/4"
            style={{
              opacity: currentImage === 2 ? 1 : 0,
            }}
          >
            <Image
              src={`/images/compare/compare3${isMobile ? '_mobile' : ''}.png`}
              fill
              alt="compare"
              className={'h-[200px] w-auto object-contain m:h-[150px]'}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

const ShopAndSave: FC = () => (
  <div
    className={`flex flex-col gap-[20px] rounded bg-galaxy-0 p-[20px] m:p-5`}
  >
    <div className={'flex w-full items-center justify-center'}>
      <Image
        src="/images/compare_car_image.png"
        width={640}
        height={370}
        alt="compare"
        className={'h-auto w-full'}
      />
    </div>
    <div className="flex flex-col items-start justify-between gap-[20px] self-stretch">
      <div className="flex flex-col items-start justify-start gap-0.5 self-stretch">
        <div className="pb-[10px] text-title24px text-brandSecondary">
          Compare Between Models
        </div>
      </div>
      <div className="text-body16px text-white">
        Explore detailed specification and ratings between different vehicle
        models, and find which one delivers the best value to you!
      </div>
      <div className="flex w-full flex-row justify-start">
        <Link
          className="text-body16px text-white underline decoration-brandSecondary decoration-2 underline-offset-[5px]"
          activeClassName="border-b-2 border-b-electricGreen-70"
          href="/compare"
          analyticsEvent={{
            ...GOOGLE_TRACK_INFO.compareEVHomePageButton,
            pageTarget: '/compare',
          }}
        >
          {COMPARE_EVS}
        </Link>
      </div>
    </div>
  </div>
);

export const CompareCTACard = ({ variant }: { variant?: 'VehicleFinder' }) => {
  if (variant === 'VehicleFinder') {
    return <VehicleFinder />;
  }
  return <ShopAndSave />;
};
