import React from 'react';
import RotatorDotGroup from '@/components/molecules/RotatorDotGroup';
import CarouselDirectionButton from '@/components/atoms/legacy/CarouselButton';
import { Component } from '@/types/interfaces';
import { Direction } from '@/types/enums';
import useWindowSize from '@/hooks/useWindowSize';
import styles from './Carousel.module.scss';

export interface CarouselProps extends Component {
  snap?: boolean;
  infinite?: boolean;
  /** Decides the positioning of the left/right arrow buttons relative to the `Carousel`'s image's content area. Default `'outside'`. */
  navigationPosition?: 'inside' | 'outside' | 'bottom';
  showDirectionButtons?: boolean;
  imagesLength: number;
  currentIndex?: number;
  setCurrentIndex?: (index: number) => void;
  selectedImageIndex?: number;
  isShowingDots?: boolean;
  /** If `navigationPosition` isn't `'outside'` and `showDirectionButtons` is set to `true`, then the left/right buttons
   * will always be visible when set to `true`. Otherwise, it shows the default behaviour, where buttons are only visible
   * when the user hovers over the `Carousel` component. Default `true`.
   */
  showButtonsOnHover?: boolean;
  slideWrapperClassName?: string;
}

const Carousel: React.FC<CarouselProps> = ({
  className = '',
  slideWrapperClassName = '',
  snap = false,
  infinite = false,
  navigationPosition = 'outside',
  children,
  showDirectionButtons = true,
  imagesLength,
  currentIndex = 0,
  setCurrentIndex,
  selectedImageIndex = 0,
  isShowingDots = false,
  showButtonsOnHover = true,
}) => {
  const [page, setPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(0);
  const [dragging, setDragging] = React.useState(false);
  const [scrollable, setScrollable] = React.useState(false);
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const startX = React.useRef(0);
  const dragged = React.useRef(false);

  const { isMobile } = useWindowSize();

  const scrollTo = (n: number) => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        left: n * carouselRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      if (
        carouselRef.current &&
        carouselRef.current.scrollWidth >= carouselRef.current.clientWidth
      ) {
        setScrollable(true);
      } else {
        setScrollable(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    const carouselRefTarget = carouselRef.current;
    let snapTimeout: NodeJS.Timeout;
    let throttleTimeout: NodeJS.Timeout;
    let throttle = false;
    const updatePage = () => {
      if (throttle) {
        return;
      }
      if (carouselRefTarget) {
        const newPage = Math.round(
          carouselRefTarget.scrollLeft / carouselRefTarget.offsetWidth
        );
        setPage(newPage);
        if (setCurrentIndex) {
          setCurrentIndex(newPage);
        }
        if (snap) {
          if (snapTimeout) {
            clearTimeout(snapTimeout);
          }
          snapTimeout = setTimeout(() => {
            scrollTo(
              Math.round(
                carouselRefTarget.scrollLeft / carouselRefTarget.offsetWidth
              )
            );
          }, 150);
        }
      }
      throttle = true;
      throttleTimeout = setTimeout(() => {
        throttle = false;
      }, 1000 / 60);
    };
    if (carouselRefTarget) {
      carouselRefTarget.addEventListener('scroll', updatePage);
      setMaxPage(
        Math.min(
          Math.ceil(
            carouselRefTarget.scrollWidth / carouselRefTarget.clientWidth
          ),
          5
        )
      );
    }
    return () => {
      clearTimeout(throttleTimeout);
      clearTimeout(snapTimeout);
      if (carouselRefTarget) {
        carouselRefTarget.removeEventListener('scroll', updatePage);
      }
    };
  }, [page, setCurrentIndex, snap]);

  React.useEffect(() => {
    if (dragging) {
      const carouselEl = carouselRef.current;
      if (!carouselEl) {
        return () => {};
      }
      let draggedTimeout: NodeJS.Timeout;
      const scrollLeft = carouselEl.scrollLeft || 0;
      const handleMouseUp = () => {
        setDragging(false);
        if (draggedTimeout) {
          clearTimeout(draggedTimeout);
        }
        draggedTimeout = setTimeout(() => {
          dragged.current = false;
        });
      };
      const handleMouseMove = (evt: MouseEvent) => {
        const x = evt.pageX - (carouselEl.offsetLeft || 0);
        const scroll = x - startX.current;
        if (carouselEl) {
          carouselEl.scrollLeft = scrollLeft - scroll;
        }
        dragged.current = true;
      };
      const handleParentClick = (evt: MouseEvent) => {
        if (dragged.current) {
          evt.preventDefault();
          evt.stopPropagation();
        }
      };
      const parentATag = carouselEl.closest('a');
      if (parentATag) {
        parentATag.addEventListener('click', handleParentClick);
      }
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        if (parentATag) {
          setTimeout(() =>
            parentATag.removeEventListener('click', handleParentClick)
          );
        }
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }
    return () => {};
  }, [dragging]);

  const scroll = (direction: 1 | -1) => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      let targetLeft = scrollLeft + direction * clientWidth;
      if (infinite) {
        if (
          direction === 1 &&
          targetLeft + clientWidth >= scrollWidth + clientWidth
        ) {
          targetLeft = 0;
        } else if (direction === -1 && scrollLeft <= 0) {
          targetLeft = scrollWidth - clientWidth;
        }
      }
      carouselRef.current.scrollTo({
        left: targetLeft,
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    scroll(-1);
  };

  const scrollRight = (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    scroll(1);
  };

  React.useEffect(() => {
    if (selectedImageIndex !== currentIndex && carouselRef.current) {
      carouselRef.current.scrollTo({
        left: currentIndex * carouselRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  }, [currentIndex, selectedImageIndex]);

  return (
    <section
      className={`relative ${
        showDirectionButtons && navigationPosition === 'bottom'
          ? 'pb-[55px]'
          : ''
      } ${className}`}
    >
      {scrollable &&
        imagesLength > 1 &&
        showDirectionButtons &&
        navigationPosition !== 'bottom' && (
          <section
            className={
              showButtonsOnHover ? 'hidden group-hover:block' : undefined
            }
          >
            <div
              className={`${page === imagesLength - 1 || infinite ? 'flex' : 'hidden'} `}
            >
              <CarouselDirectionButton
                className={`absolute left-l top-1/2 z-10 ${
                  navigationPosition === 'outside' ? '-translate-y-1/2' : ''
                } transform`}
                direction={Direction.Left}
                onClick={scrollLeft}
              />
            </div>
            <div className={`${page === 0 || infinite ? 'flex' : 'hidden'} `}>
              <CarouselDirectionButton
                className={`absolute right-l top-1/2 z-10 ${
                  navigationPosition === 'outside' ? '-translate-y-1/2' : ''
                } transform`}
                direction={Direction.Right}
                onClick={scrollRight}
              />
            </div>
          </section>
        )}
      <div
        className={`h-full overflow-x-auto ${styles.carousel}`}
        onMouseDown={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          setDragging(true);
          startX.current = evt.pageX - (carouselRef?.current?.offsetLeft || 0);
        }}
        onClick={(evt) => {
          if (dragged.current) {
            evt.preventDefault();
            evt.stopPropagation();
          }
        }}
        ref={carouselRef}
      >
        <>
          <div
            className={`relative flex h-full flex-nowrap [&>*]:shrink-0 ${slideWrapperClassName}`}
          >
            {children}
          </div>
          {isShowingDots && !(navigationPosition === 'bottom') && (
            <div className="absolute bottom-4 flex w-full flex-wrap justify-center">
              <RotatorDotGroup
                size={maxPage}
                selected={page}
                imagesLength={imagesLength}
              />
            </div>
          )}
          {scrollable &&
            showDirectionButtons &&
            navigationPosition === 'bottom' && (
              <div className={`absolute flex h-[55px] w-full`}>
                <div className="absolute flex h-full w-full flex-wrap items-center justify-center">
                  {isShowingDots && (
                    <RotatorDotGroup
                      size={maxPage}
                      selected={page}
                      imagesLength={imagesLength}
                    />
                  )}
                </div>
                <div
                  className={`absolute top-s ${
                    isMobile ? 'hidden' : 'flex'
                  } w-full flex-wrap items-center justify-end pr-[40px]`}
                >
                  <div className="flex gap-s">
                    <div
                      className={`${page === imagesLength - 1 || infinite ? 'flex' : 'hidden'} `}
                    >
                      <CarouselDirectionButton
                        direction={Direction.Left}
                        onClick={scrollLeft}
                      />
                    </div>

                    <div
                      className={`${page === 0 || infinite ? 'flex' : 'hidden'} `}
                    >
                      <CarouselDirectionButton
                        direction={Direction.Right}
                        onClick={scrollRight}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </>
      </div>
    </section>
  );
};

export default Carousel;
