import { IconBodyType } from '@/components/atoms/BodyTypeIcon/BodyTypeIcon';
import { ShopByBodyType } from '@/components/molecules/ShopByBodyType/ShopByBodyType';
import { ShopByPopularMakes } from '@/components/molecules/ShopByPopularMakes/ShopByPopularMakes';
import { FC } from 'react';
import { handleFilteredSearch } from '@/lib/search';
import { useZipContext } from '@/context/ZipContext';

export const HomePageShopType: FC<{ makes: string[] }> = ({ makes }) => {
  const { userZip, customZip } = useZipContext();
  const { postal, country } = userZip || {};

  const handleSearch = (input: string | IconBodyType) => {
    handleFilteredSearch({
      input,
      zip: customZip || postal,
      country,
    });
  };

  return (
    <div className="flex flex-col">
      <ShopByBodyType onClick={handleSearch} />
      <ShopByPopularMakes onClick={handleSearch} allMakes={makes} />
    </div>
  );
};
