import React from 'react';
import { Direction } from '@/types/enums';
import { ScComponent } from '@/types/interfaces';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export interface CarouselButtonProps
  extends ScComponent,
    Pick<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'disabled' | 'onMouseEnter' | 'onMouseLeave'
    > {
  direction: Direction;
  iconColor?: string;
  size?: 'small' | 'medium';
}

const CarouselDirectionButton: React.FC<CarouselButtonProps> = ({
  className,
  direction,
  iconColor = '#080812',
  size = 'medium',
  ...props
}) => {
  const iconSize = size === 'small' ? 16 : 20;
  return (
    <button
      className={`flex h-[44px] w-[44px] items-center justify-center rounded-full border-[1px] bg-white/70 shadow-[0px_1.667px_3.33px_0px_rgba(64,64,75,0.1)] ${
        size === 'small' ? 'h-[24px] w-[24px]' : 'h-[40px] w-[40px]'
      } border border-neutral-900 ${className}`}
      type="button"
      aria-label={
        direction === Direction.Right ? 'Select next' : 'Select previous'
      }
      {...props}
    >
      {direction === Direction.Right ? (
        <ChevronRightIcon
          stroke={iconColor}
          width={iconSize}
          height={iconSize}
        />
      ) : (
        <ChevronLeftIcon
          stroke={iconColor}
          width={iconSize}
          height={iconSize}
        />
      )}
    </button>
  );
};

export default CarouselDirectionButton;
